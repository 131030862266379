import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

// Theme
import { primaryTheme } from '../theme';

// Styles
import { Flex, Head, Section, SubHead, Text } from '../styles';

// Images
import demo from '../images/demo-page.png';
import arrow from '../images/arrow-left.svg';
import back from '../images/arrow-left-white.svg';

// Components
import { Layout, Quote, Space } from '../components';
import SidebarContainer from '../sections/SidebarContainer';
import EmptySidebar from '../components/EmptySidebar';
import PageLayout from '../layouts/PageLayout';

const Demo = () => {
    return (
        <ThemeProvider theme={primaryTheme}>
            <Layout>
                <SidebarContainer>
                    <EmptySidebar>
                        <Section height="90vh">
                            <Flex justifyContent="flex-end">
                                <ReturnWrapper>
                                    <Back>Back</Back>
                                    <span> By William Smith</span>
                                    <span> December 21, 2017</span>
                                </ReturnWrapper>
                            </Flex>
                        </Section>
                    </EmptySidebar>
                </SidebarContainer>

                <PageLayout>
                    <Head>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                    </Head>
                    <Space height="20" />
                    <Image src={demo} alt="demo" height={340} />
                    <Quote>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quia, repudiandae? Lorem ipsum dolor sit amet,
                        consectetur adipisicing elit. Quia, repudiandae?
                    </Quote>
                    <Text>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Blanditiis dicta earum, eius ipsum itaque iure
                        quam voluptate? A blanditiis corporis cum dicta eligendi
                        hic necessitatibus provident, quasi rem temporibus
                        voluptatem.
                    </Text>
                    <Text>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Blanditiis dicta earum, eius ipsum itaque iure
                        quam voluptate? A blanditiis corporis cum dicta eligendi
                        hic necessitatibus provident, quasi rem temporibus
                        voluptatem.
                    </Text>
                    <Space height="50" />
                    <SubHead bold>
                        Learn more about Blockchain digital identity
                    </SubHead>
                    <Text>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Blanditiis dicta earum, eius ipsum itaque iure
                        quam voluptate? A blanditiis corporis cum dicta eligendi
                        hic necessitatibus provident, quasi rem temporibus
                        voluptatem.Lorem ipsum dolor sit amet, consectetur
                        adipisicing elit. Blanditiis dicta earum, eius ipsum
                        itaque iure quam voluptate? A blanditiis corporis cum
                        dicta eligendi hic necessitatibus provident, quasi rem
                        temporibus voluptatem.Lorem ipsum dolor sit amet,
                        consectetur adipisicing elit. Blanditiis dicta earum,
                        eius ipsum itaque iure quam voluptate? A blanditiis
                        corporis cum dicta eligendi hic necessitatibus
                        provident, quasi rem temporibus voluptatem.
                    </Text>
                    <Text>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Blanditiis dicta earum, eius ipsum itaque iure
                        quam voluptate? A blanditiis corporis cum dicta eligendi
                        hic necessitatibus provident, quasi rem temporibus
                        voluptatem.Lorem ipsum dolor sit amet, consectetur
                        adipisicing elit. Blanditiis dicta earum, eius ipsum
                        itaque iure quam voluptate? A blanditiis corporis cum
                        dicta eligendi hic
                    </Text>

                    <List>
                        <Item>
                            Lorem ipsum. Lorem ipsum dolor sit amet, consectetur
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Deserunt, totam! adipisicing elit. Facere,
                            iusto.
                        </Item>
                        <Item>
                            Lorem ipsum. Lorem ipsum dolor sit amet, consectetur
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Deserunt, totam! adipisicing elit. Facere,
                            iusto.
                        </Item>
                        <Item>
                            Lorem ipsum. Lorem ipsum dolor sit amet, consectetur
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Deserunt, totam! adipisicing elit. Facere,
                            iusto.
                        </Item>
                        <Item>
                            Lorem ipsum. Lorem ipsum dolor sit amet, consectetur
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Deserunt, totam! adipisicing elit. Facere,
                            iusto.
                        </Item>
                        <Item>
                            Lorem ipsum. Lorem ipsum dolor sit amet, consectetur
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Deserunt, totam! adipisicing elit. Facere,
                            iusto.
                        </Item>
                        <Item>Lorem ipsum.</Item>
                    </List>
                    <Space height="50" />
                    <Line height="3px" width="100%" />
                    <Space height="20" />

                    <Actions>
                        <Action>
                            <Before>Previous</Before>
                        </Action>
                        <Action>
                            <After>Next</After>
                        </Action>
                    </Actions>
                    <Space height="50" />
                </PageLayout>
            </Layout>
        </ThemeProvider>
    );
};

export default Demo;

const indent = 70;

const mobileIndent = 32;

const Image = styled.img`
    position: relative;
    margin: 0 0 0 ${indent * -1}px;
    width: calc(100% + ${indent}px);
    height: ${props => props.height || 'auto'};
    object-fit: cover;
    @media (max-width: ${props => props.theme.sizes.phone}px) {
        margin: 0 -${mobileIndent}px 0 -${mobileIndent}px;
        width: calc(100% + ${mobileIndent * 2}px);
    }
`;

const ReturnWrapper = styled.div`
    display: flex;
    color: white;
    flex-direction: column;
    align-self: center;
    align-items: flex-end;
`;

const Back = styled.span`
    display: flex;
    margin: 10px 0;
    cursor: pointer;
    font-weight: 800;
    &:before {
        content: '';
        height: 20px;
        width: 20px;
        background-size: 19px;
        display: block;
        margin: 0 5px 0 0;
        background-image: url(${back});
        background-repeat: no-repeat;
    }
`;

const List = styled.ul`
    font-family: ${({ theme }) => theme.fontFamily};
    list-style: none;
    counter-reset: counter;
    padding: 0;
    margin: 0;
    li {
        &:before {
            content: counter(counter) '. ';
            margin: 0 5px 0 0;
            color: ${({ theme }) => theme.primaryColor};
            font-weight: bold;
            font-size: 28px;
            @media (max-width: ${props => props.theme.sizes.phone}px) {
                font-size: 20px;
            }
        }
    }
`;

const Item = styled.li`
    font-family: ${({ theme }) => theme.fontFamily};
    counter-increment: counter;
    color: ${({ theme }) => theme.primaryColor};
    margin: 10px 0;
`;

const Actions = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const Action = styled.span`
    font-weight: 800;
    font-size: 16px;
    color: ${({ theme }) => theme.primaryColor};
`;

const Before = styled.span`
    display: flex;
    cursor: pointer;
    &:before {
        content: '';
        height: 20px;
        width: 20px;
        background-size: 20px;
        display: inline-block;
        margin: 0 5px 0 0;
        background-image: url(${arrow});
        background-repeat: no-repeat;
    }
`;

const After = styled.span`
    display: flex;
    cursor: pointer;
    &:after {
        content: '';
        height: 20px;
        width: 20px;
        background-size: 20px;
        display: inline-block;
        margin: 0 0 0 5px;
        background-image: url(${arrow});
        transform: rotate(180deg);
        background-repeat: no-repeat;
    }
`;

const Line = styled.span`
    width: ${props => props.width};
    height: ${props => props.height};
    background: ${props => props.theme.primaryColor};
    display: inline-block;
`;
